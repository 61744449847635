import React from 'react'
import styles from '../../styles/styles'

const Sponsored = () => {
  return (
    <div
      className={`${styles.section} hidden sm:block bg-white py-10 px-5 mb-12 cursor-pointer rounded-xl`}
    >
      <div className="flex justify-between w-full">
        <div className="flex items-start">
          <img
            src="https://res.cloudinary.com/ddarxiade/image/upload/v1728297564/DALL_E_2024-10-07_12.28.04_-_A_captivating_banner_image_with_a_vibrant_color_scheme_similar_to_the_reference_image_https___themes.rslahmed.dev_rafcart_assets_images_banner-2.jpg_m8lll1.webp"
            alt=""
            style={{ width: '150px', objectFit: 'contain' }}
          />
        </div>
        <div className="flex items-start">
          <img
            src="https://res.cloudinary.com/ddarxiade/image/upload/v1728298468/DALL_E_2024-10-07_12.53.24_-_A_captivating_banner_image_with_a_vibrant_color_scheme_featuring_bold_clear_and_bright_text_for_easy_readability._The_image_should_focus_on_luxuriou_o6q4nv.webp"
            style={{ width: '150px', objectFit: 'contain' }}
            alt=""
          />
        </div>
        <div className="flex items-start">
          <img
            src="https://res.cloudinary.com/ddarxiade/image/upload/v1728301121/DALL_E_2024-10-07_13.38.09_-_An_image_featuring_a_wide_collection_of_various_types_of_luxurious_Frontal_Hairs_with_more_length_to_fit_a_hero_page_on_a_website._The_image_should_i_okvkp0.webp"
            style={{ width: '150px', objectFit: 'contain' }}
            alt=""
          />
        </div>
        <div className="flex items-start">
          <img
            src="https://res.cloudinary.com/ddarxiade/image/upload/v1728300497/DALL_E_2024-10-07_13.27.42_-_An_image_featuring_a_collection_of_various_types_of_luxurious_Frontal_Hairs_arranged_neatly._The_image_includes_different_textures_lengths_and_style_g9wrrn.webp"
            style={{ width: '150px', objectFit: 'contain' }}
            alt=""
          />
        </div>
        <div className="flex items-start">
          <img
            src="https://res.cloudinary.com/ddarxiade/image/upload/v1728326874/DALL_E_2024-10-07_20.46.47_-_A_captivating_banner_image_with_a_vibrant_color_scheme_similar_to_the_previous_design._The_image_should_feature_a_modern_design_with_a_promotional_foc_crvapr.webp"
            style={{ width: '150px', objectFit: 'contain' }}
            alt=""
          />
        </div>
      </div>
    </div>
  )
}

export default Sponsored
