import React from 'react'
import { Link } from 'react-router-dom'
import styles from '../../../styles/styles'

const Hero = () => {
  return (
    <div
      className={`relative min-h-[90vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.noramlFlex}`}
      style={{
        backgroundImage:
          'url(https://res.cloudinary.com/ddarxiade/image/upload/v1728301121/DALL_E_2024-10-07_13.38.09_-_An_image_featuring_a_wide_collection_of_various_types_of_luxurious_Frontal_Hairs_with_more_length_to_fit_a_hero_page_on_a_website._The_image_should_i_okvkp0.webp)',
      }}
    >
      <div
        className={`${styles.section} w-[90%] 800px:w-[60%] bg-black p-[0.5px]`}
      >
        <h1
          className={`text-[35px] leading-[1.2] 800px:text-[60px] text-white font-[600] capitalize`}
        >
          Luxurious Frontal Hairs <br /> Shipping To All African Countries & The
          World
        </h1>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-white">
          Discover the Beauty of Premium Frontal Hairs at an Unbeatable Price of
          just $19.99 each!
          <br /> Available in all types, our luxurious Frontals offer the
          perfect blend of style and quality. With a minimum order of 10 items,
          this is your chance to stock up for less. <br /> Hurry—our exclusive
          Clearance Sale ends on 31 October 2024!
        </p>
        <Link to="/products" className="inline-block">
          <div className={`${styles.button} mt-5`}>
            <span className="text-[#fff] font-[Poppins] text-[18px]">
              Shop Now
            </span>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default Hero
